<template>
  <div @keydown.esc="reject">
    <v-container>
      <h1>Eingabe der Trauerfälle</h1>
      <p>
        Bitte überprüfe alle Angaben aus der Traueranzeige und ergänze den Wohnort.
      </p>
      <v-form ref="dataInput" @submit.prevent="pushData" @keydown.esc="reject">
        <v-row>
          <v-col cols="5">
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="data.prename.value"
                    :error="!data.prename.certain"
                    @click="data.prenam.certain = true"
                    outlined
                    label="Vorname"
                    hint="Spitznamen in Klammern"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    v-model="data.surname.value"
                    :error="!data.surname.certain"
                    @click="data.surname.certain = true"
                    outlined
                    label="Nachname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="data.title.value"
                    :error="!data.title.certain"
                    @click="data.title.certain = true"
                    outlined
                    label="Titel"
                    hint="Leer lassen, wenn kein Titel vorhanden ist"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    v-model="data.birthName.value"
                    :error="!data.birthName.certain"
                    @click="data.birthName.certain = true"
                    outlined
                    label="Geburtsname"
                    hint="Leer lassen, wenn kein Geburtsname vorhanden ist"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="data.birth.value"
                    :error="!data.birth.certain"
                    @click="data.birth.certain = true"
                    outlined
                    :rules="dateRule"
                    label="Geburtsdatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    v-model="data.obit.value"
                    :error="!data.obit.certain"
                    @click="data.obit.certain = true"
                    outlined
                    :rules="dateRule2"
                    label="Sterbedatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <b>Quelle:</b><br>
                  <p>{{ data.relatedTo }}</p>
                </v-col>
                <v-col class="py-0">
                  <b>Sitz der Zeitung:</b><br>
                  <p>{{ data.paperLocation }}</p>
                </v-col>
              </v-row>
              <!-- Wohnort mit Google API -->
              <v-text-field
                ref="autocomplete" :error="!data.lastResidence.certain" @input="residenceTmp = $event" :value="residence" outlined label="Adresse" placeholder=""></v-text-field>
          </v-col>
          <v-col cols="7" style="padding: 0 9%">
            <v-img referrerpolicy="no-referrer" :src="data.imgUrl" :alt="data.caseUrl" @click="openPage"></v-img>
            <p>
              Das Bild zeigt eine Danksagung, einen Nachruf oder ein Jahresgedächtnis? Dann klicke hier:
            </p>
          </v-col>
        </v-row>

        <!-- Buttons -->
        <v-row>
          <v-col cols="7">
            <v-btn width="100%" class="success white--text" type="submit" height="50px" style="text-transform: none">Speichern</v-btn>
          </v-col>
          <v-col cols="5" style="padding: 12px 9%">
            <v-btn width="100%" class="error white--text" @click="reject" height="50px" style="text-transform: none">Keine Traueranzeige (ESC)</v-btn>
           </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['data'],
  data () {
    return {
      residenceTmp: '',
      dateRule: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          var format3 = ''
          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }
          if (!format.test(v) && !format2.test(v) && !format.test(auxStr) && !(format3 === v) && v) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            if (!v || format3 === v) {
              return true
            } else {
              var auxDate = v.split('.')
              if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
                return 'Bitte gib ein Datum in der Vergangenheit ein'
              } else {
                if (format2.test(v)) {
                  this.data.birth.value = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
                }
                return true
              }
            }
          }
        }
      ],
      dateRule2: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }

          if (!format.test(v) && !format2.test(v) && !format.test(auxStr)) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            var auxDate = v.split('.')
            if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
              return 'Bitte gib ein Datum in der Vergangenheit ein'
            } else {
              if (format2.test(v)) {
                this.data.obit.value = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
              }
              return true
            }
          }
        }
      ]
    }
  },
  computed: {
    residence () {
      return this.data.lastResidence.value.formatted_address || this.data.lastResidence.value
    }
  },
  watch: {
    residence () {
      this.residenceTmp = this.residence
    }
  },
  methods: {
    reject () {
      axios.post('/case', { type: 'none', cases: [this.data] })
        .then(() => {
          this.$emit('next')
        })
        .catch(() => {
        })
    },
    pushData () {
      var stringDate = this.data.obit.value.split('.').reverse().join('-') + 'T00:00:00.000Z'
      var parsedDate = new Date(stringDate)
      var now = new Date()
      if (parsedDate > now) {
        this.data.obit.certain = false
      } else {
        if (this.$refs.dataInput.validate() && (this.residenceTmp === '' || this.residenceTmp === ' ' || this.data.lastResidence.value.formatted_address)) {
          if (this.residenceTmp === '' || this.residenceTmp === ' ') {
            this.data.lastResidence.value = ''
          }
          const postData = {
            type: 'case',
            cases: [this.data]
          }
          axios.post('/case', postData)
            .then(() => {
              this.$emit('next')
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    openPage () {
      window.open(this.data.caseUrl, '_blank')
    }
  },
  created () {
    this.residenceTmp = this.residence
  },
  mounted () {
    // To demonstrate functionality of exposed component functions
    // Here we make focus on the user input
    // this.$refs.address.focus();
    let element = this.$refs.autocomplete.$el
    element = element.querySelector('input')
    var autocomplete = new window.google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */(element),
      { types: ['(regions)'], componentRestrictions: { country: 'de' } }
    )
    var self = this
    window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      const place = autocomplete.getPlace()
      self.data.lastResidence.certain = true
      self.data.lastResidence.value = place
    })
  }
}
</script>
