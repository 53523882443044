<template>
  <div>
    <!-- header -->
    <div class="header" style="text-align: right; padding: 20px 40px; margin-bottom: 20px">
      <v-btn class="error white--text" width="200px" height="50px" style="text-transform: none" @click="finish">Arbeit beenden</v-btn>
    </div>

    <div v-if="!noData && !empty">
      <input-form :data="data" @next="loadCase" v-if="data.type === 'case' || data.type === 'non_type'"></input-form>
      <resolve :data="data" v-if="resolve" @next="loadCase(); resolve = false"></resolve>
      <conflict :data="data" v-if="data.type === 'conflict' && !resolve" @updateData="data.resolve = $event; resolve = true"></conflict>
    </div>

    <!-- No Cases -->
    <div style="text-align: center" v-if="noData && !empty">
      <h1>
        Aktuell gibt es keinen neuen Fälle, die bearbeitet werden können!
      </h1>
      <v-btn class="white--text primary" height="50px" style="margin-top: 50px" @click="finish">
        Zurück zu deinem Dashboard
      </v-btn>
    </div>

    <div v-if="empty">
      <empty-input-form></empty-input-form>
    </div>

    <!-- Questions -->
    <div class="secondary" style="padding: 40px 10%; margin-top: 50px">
      <h2 style="margin-bottom: 10px">Vorgehensweise bei jedem Trauerfall:</h2>
      <ol style="margin-bottom: 30px">
        <li>
          Ist es eine Danksagung, ein Nachruf oder ein Jahresgedächtnis? Zu erkennen an z. B. "DANKE" oder älterem Sterbedatum
        </li>
        <li>
          Falls Ja: Auf roten Button drücken, Falls nein: Schritte 3-7 durchführen
        </li>
        <li>
          Vorname und Nachname überprüfen und ggf. Fehler korrigieren
        </li>
        <li>
          Geburtsname überprüfen. Wenn es keinen Geburtsnamen gibt, einfach das Feld leer lassen
        </li>
        <li>
          Geburts- und Sterbedatum überprüfen und ggf. Fehler korrigieren
        </li>
        <li>
          Den richtigen Wohnort eintragen und nach Eingabe den Ort auf Rechtschreibefehler prüfen
        </li>
        <li>
          Mit Enter bestätigen, um den Trauerfall zu speichern
        </li>
      </ol>

      <h2 style="margin-bottom: 10px">Welcher Ort ist der Richtige?</h2>
      In diesen Fällen schreiben wir den Ort hin:
      <ul style="margin-bottom: 30px">
        <li>
          Prio 1 - Der Ort steht da, ohne, dass da sonst etwas dabei steht
        </li>
        <li>
          Prio 2 - Friedhof / Sterbebort / Trauerfeier (in dieser Reihenfolge) (Geburtsort nur, wenn es sonst keine Orte gibt)
        </li>
        <li>
          Prio 3 - Traueranschrift Angehörige
        </li>
        <li>
          Prio 4 - Bestatteranschrift (Manchmal auch Traueranschrift genannt aber zu erkennen an der Adresse des Bestatters)
        </li>
      </ul>
      <p>
        Wenn es mehrere Orte gibt, nehmen wir immer den Ort mit der höchsten Priorität<br>
        Wenn bei Prio 3 und 4 deutlich zu erkennen ist, dass der Bestatterort eher der richtige Ort ist (weil die Traueranschrift zum Beispiel in einem anderen Bundesland liegt), dann kann der Bestatterort genommen werden.
      </p>

      In diesen Fällen schreiben wir den Ort <b>nicht</b> hin:
      <ul style="margin-bottom: 30px">
        <li>
          Prio 5 - Friedwald / Pflegeheim / Hospiz / Krankenhaus und ähnliches
        </li>
        <li>
          Prio 6 - kein Ort
        </li>
      </ul>

      <h2 style="margin-bottom: 10px">Wie kann ich dieses Formular am einfachsten ausfüllen?</h2>
      <p>
        Du kannst alles über die Tastatur bedienen und benötigst die Maus nicht. Dein Cursor befindet sich automatisch im Eingabe-Feld für den Ort. Nach Eingabe des Ortes kannst du einfach Enter drücken. Der Trauerfall wird gespeichert und du kannst direkt den Ort vom nächsten Trauerfall eingeben. Möchtest du zwischen den Eingabefelder springen, kannst du die Tab-Taste (vor) bzw. Tab+Shift (zurück) nutzen.
      </p>

      <h2>Fragen? Schreib uns im Google Chat oder ruf uns an: 0681 965 938 80</h2>
    </div>
  </div>
</template>

<script>
import InputForm from '@/components/cases/InputForm.vue'
import Conflict from '@/components/cases/Conflict.vue'
import Resolve from '@/components/cases/Resolve.vue'
import axios from 'axios'
import EmptyInputForm from '@/components/cases/EmptyInputForm.vue'

export default {
  data () {
    return {
      data: {},
      noData: false,
      resolve: false
    }
  },
  methods: {
    finish () {
      const id = this.data.type === 'conflict' ? this.data.cases[0]._id : this.data._id
      const id2 = this.data.type === 'conflict' ? this.data.cases[1]._id : ''
      const tmpData = this.data.type === 'conflict' ? { type: 'conflict', _id: id, _id2: id2 } : { type: 'case', _id: id }
      if (this.noData) {
        axios.post('/case/stop', { type: 'none' })
          .then(res => {
            localStorage.setItem('uv_a_w', false)
            this.$router.push({ name: 'Dashboard' })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        axios.post('/case/stop', tmpData)
          .then(res => {
            localStorage.setItem('uv_a_w', false)
            this.$router.push({ name: 'Dashboard' })
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    transformDate (date) {
      const tmp = date.split('T')[0].split('-')
      return tmp[2] + '.' + tmp[1] + '.' + tmp[0]
    },
    loadCase () {
      axios.get('/case', {})
        .then(res => {
          if (res.data === '') {
            this.noData = true
          } else {
            this.noData = false
            this.data = res.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    if (!this.empty) {
      this.loadCase()
    }

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.finish()
      next()
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },
  computed: {
    empty () {
      return this.$route.query.e
    }
  },
  components: {
    inputForm: InputForm,
    conflict: Conflict,
    resolve: Resolve,
    emptyInputForm: EmptyInputForm
  }
}
</script>
