<template>
  <div>
    <v-container>
      <h1>Eingabe der Trauerfälle</h1>
      <v-form ref="dataInputEmpty" @submit.prevent="pushData">
        <v-row>
          <v-col>
            <v-text-field
              ref="firstInput"
              v-model="data.prename"
              outlined
              label="Vorname"
              hint="Spitznamen in Klammern"
              required
              :rules="nonEmptyRule"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="data.surname"
              outlined
              label="Nachname"
              required
              :rules="nonEmptyRule"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="data.title"
              outlined
              label="Titel"
              hint="Leer lassen, wenn kein Titel vorhanden ist"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="data.birthName"
              outlined
              label="Geburtsname"
              hint="Leer lassen, wenn kein Geburtsname vorhanden ist"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="data.birthday"
              outlined
              label="Geburtsdatum"
              hint="tt.mm.jjjj or ttmmjjjj"
              :rules="dateRule"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="data.obit"
              outlined
              label="Sterbedatum"
              hint="tt.mm.jjjj or ttmmjjjj"
              required
              :rules="dateRule2"
              :error="error"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Wohnort mit Google API -->
        <v-text-field ref="autocomplete" @input="residenceTmp = $event" :value="residence" outlined label="Adresse" placeholder=""></v-text-field>

        <!-- Buttons -->
        <v-btn width="100%" class="success white--text" type="submit" height="50px" style="text-transform: none" @click="focusCursor()">Speichern</v-btn>

        <div class="secondary title" style="min-height: 50px; margin-top: 30px; padding: 20px; text-align: center">
          <p v-for="(element, i) in finishedArray" :key="i">
            {{element.title }} {{ element.prename }} {{ element.surname }}<span v-if="element.birthName !== ''"> geb. {{ element.birthName }}</span> aus {{ element.location.formatted_address }} ({{ element.birthday }} - {{ element.obit }})
          </p>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      finishedArray: [],
      data: {
        prename: '',
        surname: '',
        title: '',
        birthName: '',
        birthday: '',
        obit: '',
        location: ''
      },
      error: false,
      nonEmptyRule: [
        v => !!v || 'Eingabe wird benötigt'
      ],
      dateRule: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          var format3 = ''
          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }
          if (!format.test(v) && !format2.test(v) && !format.test(auxStr) && !(format3 === v) && v) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            if (!v || format3 === v) {
              return true
            } else {
              var auxDate = v.split('.')
              if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
                return 'Bitte gib ein Datum in der Vergangenheit ein'
              } else {
                if (format2.test(v)) {
                  this.data.birthday = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
                }
                return true
              }
            }
          }
        }
      ],
      dateRule2: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }
          if (!format.test(v) && !format2.test(v) && !format.test(auxStr)) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            var auxDate = v.split('.')
            if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
              return 'Bitte gib ein Datum in der Vergangenheit ein'
            } else {
              if (format2.test(v)) {
                this.data.obit = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
              }
              return true
            }
          }
        }
      ],
      residenceFlag: false,
      residenceTmp: ''
    }
  },
  computed: {
    residence () {
      return this.data.location.formatted_address || this.data.location
    }
  },
  watch: {
    residence () {
      this.residenceTmp = this.residence
    }
  },
  methods: {
    pushData () {
      var stringDate = this.data.obit.split('.').reverse().join('-') + 'T00:00:00.000Z'
      var parsedDate = new Date(stringDate)
      var now = new Date()
      if (parsedDate > now) {
        this.error = true
      } else {
        this.error = false
        if (this.$refs.dataInputEmpty.validate() && (this.residenceTmp === '' || this.data.location.formatted_address)) {
          if (this.residenceTmp === '') {
            this.data.location = ''
          }
          axios.post('/case/manual', this.data)
            .then(() => {
              this.finishedArray.unshift(this.data)
              this.data = {
                prename: '',
                surname: '',
                title: '',
                birthName: '',
                birthday: '',
                obit: '',
                location: ''
              }
              this.residenceFlag = false
            })
            .catch(() => {
            })
        }
      }
    },
    focusCursor () {
      this.$refs.firstInput.focus()
    }
  },
  created () {
    this.residenceTmp = this.residence
  },
  mounted () {
    // To demonstrate functionality of exposed component functions
    // Here we make focus on the user input
    // this.$refs.address.focus();
    let element = this.$refs.autocomplete.$el
    element = element.querySelector('input')
    var autocomplete = new window.google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */(element),
      { types: ['(regions)'], componentRestrictions: { country: 'de' } }
    )
    var self = this
    window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      const place = autocomplete.getPlace()
      self.data.location = place
      self.residenceFlag = true
    })
  }
}
</script>
