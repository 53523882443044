<template>
  <div>
    <!-- Same -->
    <v-container v-if="data.resolve === 'same'">
      <h1>Eingabe der Trauerfälle</h1>
      <p>
        Bitte überprüfe alle Angaben aus der Traueranzeige und ergänze den Wohnort.
      </p>
      <v-form ref="dataInputSame" @submit.prevent="pushDataSame">
        <v-row>
          <v-col cols="7">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].prename.value"
                    :error="!data.cases[0].prename.certain"
                    @click="data.cases[0].prenam.certain = true"
                    outlined
                    label="Vorname"
                    hint="Spitznamen in Klammern"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].surname.value"
                    :error="!data.cases[0].surname.certain"
                    @click="data.cases[0].surname.certain = true"
                    outlined
                    label="Nachname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].title.value"
                    :error="!data.cases[0].title.certain"
                    @click="data.cases[0].title.certain = true"
                    outlined
                    label="Titel"
                    hint="Leer lassen, wenn kein Titel vorhanden ist"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].birthName.value"
                    :error="!data.cases[0].birthName.certain"
                    @click="data.cases[0].birthName.certain = true"
                    outlined
                    label="Geburtsname"
                    hint="Leer lassen, wenn kein Geburtsname vorhanden ist"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].birth.value"
                    :error="!data.cases[0].birth.certain"
                    @click="data.cases[0].birth.certain = true"
                    outlined
                    :rules="dateRule"
                    label="Geburtsdatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].obit.value"
                    :error="!data.cases[0].obit.certain"
                    @click="data.cases[0].obit.certain = true"
                    outlined
                    :rules="dateRule2"
                    label="Sterbedatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Quelle:</b><br>
                  <p>{{ data.cases[0].relatedTo }}</p>
                </v-col>
                <v-col>
                  <b>Sitz der Zeitung:</b><br>
                  <p>{{ data.cases[0].paperLocation }}</p>
                </v-col>
              </v-row>
              <!-- Wohnort mit Google API -->
              <v-text-field ref="autocompleteResolveSame" :error="!data.cases[0].lastResidence.certain" @input="residenceTmp0 = $event" :value="residenceSame" outlined label="Adresse" placeholder=""></v-text-field>
          </v-col>
          <v-col cols="5" style="padding: 0 9%">
            <v-img referrerpolicy="no-referrer" :src="data.cases[0].imgUrl" :alt="data.cases[0].caseUrl" @click="openPage(0)"></v-img>
            <v-img referrerpolicy="no-referrer" :src="data.cases[1].imgUrl" :alt="data.cases[1].caseUrl" @click="openPage(0)" v-if="data.cases[1].imgUrl !== data.cases[0].imgUrl"></v-img>
            <p>
              Das Bild zeigt eine Danksagung, einen Nachruf oder ein Jahresgedächtnis? Dann klicke hier:
            </p>
          </v-col>
        </v-row>

        <!-- Buttons -->
        <v-row>
          <v-col cols="7">
            <v-btn width="100%" class="success white--text" type="submit" height="50px" style="text-transform: none">Speichern</v-btn>
          </v-col>
          <v-col cols="5" style="padding: 12px 9%">
            <v-btn width="100%" class="error white--text" @click="reject(0)" height="50px" style="text-transform: none">Keine Traueranzeige</v-btn>
           </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- Different -->
    <v-container v-else>
      <h1>Eingabe der Trauerfälle</h1>
      <p>
        Bitte überprüfe alle Angaben aus der Traueranzeige und ergänze den Wohnort.
      </p>
      <v-form ref="dataInputDifferent" @submit.prevent="pushDataDifferent">
        <v-row>
          <v-col cols="7">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].prename.value"
                    :error="!data.cases[0].prename.certain"
                    @click="data.cases[0].prenam.certain = true"
                    outlined
                    label="Vorname"
                    hint="Spitznamen in Klammern"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].surname.value"
                    :error="!data.cases[0].surname.certain"
                    @click="data.cases[0].surname.certain = true"
                    outlined
                    label="Nachname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].title.value"
                    :error="!data.cases[0].title.certain"
                    @click="data.cases[0].title.certain = true"
                    outlined
                    label="Titel"
                    hint="Leer lassen, wenn kein Titel vorhanden ist"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].birthName.value"
                    :error="!data.cases[0].birthName.certain"
                    @click="data.cases[0].birthName.certain = true"
                    outlined
                    label="Geburtsname"
                    hint="Leer lassen, wenn kein Geburtsname vorhanden ist"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].birth.value"
                    :error="!data.cases[0].birth.certain"
                    @click="data.cases[0].birth.certain = true"
                    outlined
                    :rules="dateRule"
                    label="Geburtsdatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[0].obit.value"
                    :error="!data.cases[0].obit.certain"
                    @click="data.cases[0].obit.certain = true"
                    outlined
                    :rules="dateRule2"
                    label="Sterbedatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Quelle:</b><br>
                  <p>{{ data.cases[0].relatedTo }}</p>
                </v-col>
                <v-col>
                  <b>Sitz der Zeitung:</b><br>
                  <p>{{ data.cases[0].paperLocation }}</p>
                </v-col>
              </v-row>
              <!-- Wohnort mit Google API -->
              <v-text-field ref="autocompleteResolveDifferent1" :error="!data.cases[0].lastResidence.certain" @input="residenceTmp0 = $event" :value="residenceDifferent1" outlined label="Adresse" placeholder=""></v-text-field>
          </v-col>
          <v-col cols="5" style="padding: 0 9%">
            <v-img referrerpolicy="no-referrer" :src="data.cases[0].imgUrl" :alt="data.cases[0].caseUrl" @click="openPage(1)"></v-img>
            <p>
              Das Bild zeigt eine Danksagung, einen Nachruf oder ein Jahresgedächtnis? Dann klicke hier:
            </p>
          </v-col>
        </v-row>

        <!-- Buttons -->
        <v-row>
          <v-col cols="7">
            <v-btn width="100%" class="success white--text" height="50px" style="text-transform: none" @click="scrollTo">Weiter</v-btn>
          </v-col>
          <v-col cols="5" style="padding: 12px 9%">
            <v-btn width="100%" class="error white--text" @click="reject(1)" height="50px" style="text-transform: none">Keine Traueranzeige</v-btn>
           </v-col>
        </v-row>

        <h1 style="margin-top: 40px">Eingabe der Trauerfälle</h1>
        <p>
          Bitte überprüfe alle Angaben aus der Traueranzeige und ergänze den Wohnort.
        </p>
        <!-- Second -->
        <v-row id="second">
          <v-col cols="7">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[1].prename.value"
                    :error="!data.cases[1].prename.certain"
                    @click="data.cases[1].prenam.certain = true"
                    outlined
                    label="Vorname"
                    hint="Spitznamen in Klammern"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[1].surname.value"
                    :error="!data.cases[1].surname.certain"
                    @click="data.cases[1].surname.certain = true"
                    outlined
                    label="Nachname"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[1].title.value"
                    :error="!data.cases[1].title.certain"
                    @click="data.cases[1].title.certain = true"
                    outlined
                    label="Titel"
                    hint="Leer lassen, wenn kein Titel vorhanden ist"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[1].birthName.value"
                    :error="!data.cases[1].birthName.certain"
                    @click="data.cases[1].birthName.certain = true"
                    outlined
                    label="Geburtsname"
                    hint="Leer lassen, wenn kein Geburtsname vorhanden ist"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.cases[1].birth.value"
                    :error="!data.cases[1].birth.certain"
                    @click="data.cases[1].birth.certain = true"
                    outlined
                    label="Geburtsdatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                    :rules="dateRule3"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.cases[1].obit.value"
                    :error="!data.cases[1].obit.certain"
                    @click="data.cases[1].obit.certain = true"
                    outlined
                    label="Sterbedatum"
                    hint="tt.mm.jjjj or ttmmjjjj"
                    :rules="dateRule4"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Quelle:</b><br>
                  <p>{{ data.cases[1].relatedTo }}</p>
                </v-col>
                <v-col>
                  <b>Sitz der Zeitung:</b><br>
                  <p>{{ data.cases[1].paperLocation }}</p>
                </v-col>
              </v-row>
              <!-- Wohnort mit Google API -->
              <v-text-field ref="autocompleteResolveDifferent2" :error="!data.cases[0].lastResidence.certain" @input="residenceTmp1 = $event" :value="residenceDifferent2" outlined label="Adresse" placeholder=""></v-text-field>
          </v-col>
          <v-col cols="5" style="padding: 0 9%">
            <v-img referrerpolicy="no-referrer" :src="data.cases[1].imgUrl" :alt="data.cases[0].caseUrl" @click="openPage(2)"></v-img>
            <p>
              Das Bild zeigt eine Danksagung, einen Nachruf oder ein Jahresgedächtnis? Dann klicke hier:
            </p>
          </v-col>
        </v-row>

        <!-- Buttons -->
        <v-row>
          <v-col cols="7">
          </v-col>
          <v-col cols="5" style="padding: 12px 9%">
            <v-btn width="100%" class="error white--text" @click="reject(2)" height="50px" style="text-transform: none">Keine Traueranzeige</v-btn>
           </v-col>
        </v-row>
        <div style="padding: 40px 12%">
          <v-btn width="100%" class="success white--text" height="50px" style="text-transform: none" type="submit">Beide Fälle speichern</v-btn>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['data'],
  data () {
    return {
      residenceTmp0: '',
      residenceTmp1: '',
      dateRule: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          var format3 = ''
          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }
          if (!format.test(v) && !format2.test(v) && !format.test(auxStr) && !(format3 === v) && v) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            if (!v || format3 === v) {
              return true
            } else {
              var auxDate = v.split('.')
              if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
                return 'Bitte gib ein Datum in der Vergangenheit ein'
              } else {
                if (format2.test(v)) {
                  this.data.cases[0].birth.value = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
                }
                return true
              }
            }
          }
        }
      ],
      dateRule2: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }

          if (!format.test(v) && !format2.test(v) && !format.test(auxStr)) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            var auxDate = v.split('.')
            if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
              return 'Bitte gib ein Datum in der Vergangenheit ein'
            } else {
              if (format2.test(v)) {
                this.data.cases[0].obit.value = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
              }
              return true
            }
          }
        }
      ],
      dateRule3: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          var format3 = ''
          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }
          if (!format.test(v) && !format2.test(v) && !format.test(auxStr) && !(format3 === v) && v) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            if (!v || format3 === v) {
              return true
            } else {
              var auxDate = v.split('.')
              if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
                return 'Bitte gib ein Datum in der Vergangenheit ein'
              } else {
                if (format2.test(v)) {
                  this.data.cases[1].birth.value = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
                }
                return true
              }
            }
          }
        }
      ],
      dateRule4: [
        v => {
          /* eslint-disable */
          var format = /^(0?[1-9]|[12][0-9]|3[01])[\/\.\-](0?[1-9]|1[012])[\/\.\-]\d{4}$/
          /* eslint-enable */

          /* eslint-disable */
          var format2 = /^\d{8}$/
          /* eslint-enable */

          if (format2.test(v)) {
            var auxStr = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
          }

          if (!format.test(v) && !format2.test(v) && !format.test(auxStr)) {
            return 'Bitte gib ein mögliches Datum ein (Format: TT.MM.JJJJ) or (Format: TTMMJJJJ)'
          } else {
            var auxDate = v.split('.')
            if (new Date(Date.now()) <= new Date(auxDate[1] + '.' + auxDate[0] + '.' + auxDate[2])) {
              return 'Bitte gib ein Datum in der Vergangenheit ein'
            } else {
              if (format2.test(v)) {
                this.data.cases[1].obit.value = v.substr(0, 2) + '.' + v.substr(2, 2) + '.' + v.substr(4)
              }
              return true
            }
          }
        }
      ]
    }
  },
  computed: {
    residenceSame () {
      return this.data.cases[0].lastResidence.value.formatted_address || this.data.cases[0].lastResidence.value
    },
    residenceDifferent1 () {
      return this.data.cases[0].lastResidence.value.formatted_address || this.data.cases[0].lastResidence.value
    },
    residenceDifferent2 () {
      return this.data.cases[1].lastResidence.value.formatted_address || this.data.cases[1].lastResidence.value
    }
  },
  watch: {
    residenceSame () {
      this.residenceTmp0 = this.residenceSame
    },
    residenceDifferent1 () {
      this.residenceTmp0 = this.rresidenceDifferent1
    },
    residenceDifferent2 () {
      this.residenceTmp1 = this.rresidenceDifferent2
    }
  },
  methods: {
    scrollTo () {
      this.$vuetify.goTo('#second', { duration: 1000, offset: 0, easing: 'easeInOutCubic' })
    },
    reject (element) {
      // element 0 => Same; 1 => Different1; 2 => Different2
      if (element > 0) {
        this.data.cases[element - 1].type = 'none'
      } else {
        this.data.cases[0].type = 'none'
        this.data.cases[1].type = 'none'
        axios.post('/case', { type: 'conflict', cases: [this.data.cases[0], this.data.cases[1]] })
          .then(() => {
            this.$emit('next')
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    pushDataSame () {
      if (this.$refs.dataInputSame.validate() && (this.residenceTmp0 === '' || this.residenceTmp0 === ' ' || this.data.cases[0].lastResidence.value.formatted_address)) {
        this.data.cases[1] = this.data.cases[0]
        // Push data to server
        axios.post('/case', this.data)
          .then(() => {
            this.$emit('next')
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    pushDataDifferent () {
      if (this.$refs.dataInputDifferent.validate() && (this.data.cases[0].type === 'none' || this.residenceTmp0 === '' || this.residenceTmp0 === ' ' || this.data.cases[0].lastResidence.value.formatted_address) && (this.data.cases[1].type === 'none' || this.residenceTmp1 === '' || this.residenceTmp1 === ' ' || this.data.cases[1].lastResidence.value.formatted_address)) {
        // Push data to server
        axios.post('/case', this.data)
          .then(() => {
            this.$emit('next')
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    openPage (element) {
      if (element < 2) {
        window.open(this.data.cases[0].caseUrl, '_blank')
      } else {
        window.open(this.data.cases[1].caseUrl, '_blank')
      }
    }
  },
  created () {
    this.residenceTmp0 = this.residenceSame
    this.residenceTmp0 = this.residenceDifferent1
    this.residenceTmp1 = this.residenceDifferent2
  },
  mounted () {
    var self = this
    if (this.$refs.autocompleteResolveSame) {
      let element = this.$refs.autocompleteResolveSame.$el
      element = element.querySelector('input')
      var autocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */(element),
        { types: ['(regions)'], componentRestrictions: { country: 'de' } }
      )
      window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
        const place = autocomplete.getPlace()
        self.data.cases[0].lastResidence.certain = true
        self.data.cases[0].lastResidence.value = place
      })
    } else {
      let element = this.$refs.autocompleteResolveDifferent1.$el
      element = element.querySelector('input')
      var autocomplete1 = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */(element),
        { types: ['(regions)'], componentRestrictions: { country: 'de' } }
      )
      window.google.maps.event.addListener(autocomplete1, 'place_changed', function () {
        const place = autocomplete1.getPlace()
        self.data.cases[0].lastResidence.certain = true
        self.data.cases[0].lastResidence.value = place
      })

      element = this.$refs.autocompleteResolveDifferent2.$el
      element = element.querySelector('input')
      var autocomplete2 = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */(element),
        { types: ['(regions)'], componentRestrictions: { country: 'de' } }
      )
      window.google.maps.event.addListener(autocomplete2, 'place_changed', function () {
        const place = autocomplete2.getPlace()
        self.data.cases[1].lastResidence.certain = true
        self.data.cases[1].lastResidence.value = place
      })
    }
  }
}
</script>
