<template>
  <div>
    <div style="text-align: center">
       <h1>Konflikt!</h1>
       <p>
         Sind das Anzeigen zur selben Person?
       </p>
     </div>
     <v-container>
       <!-- Images -->
       <v-row>
         <v-col cols="6" style="padding: 10px 9%">
           <v-img referrerpolicy="no-referrer" :src="data.cases[0].imgUrl" :alt="data.cases[0].caseUrl"></v-img>
         </v-col>
         <v-col cols="6" style="padding: 10px 9%">
           <v-img referrerpolicy="no-referrer" :src="data.cases[1].imgUrl" :alt="data.cases[1].caseUrl"></v-img>
         </v-col>
       </v-row>

       <!-- Buttons -->
       <v-row>
         <v-col cols="6" style="padding: 40px 9%">
           <v-btn width="100%" height="50px" class="success white--text" style="text-transform: none" @click="same">Die selbe Person</v-btn>
         </v-col>
         <v-col cols="6" style="padding: 40px 9%">
           <v-btn width="100%" height="50px" class="error white--text" style="text-transform: none" @click="different">Unterschiedliche Personen</v-btn>
         </v-col>
       </v-row>
     </v-container>
  </div>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    same () {
      this.$emit('updateData', 'same')
    },
    different () {
      this.$emit('updateData', 'different')
    }
  }
}
</script>
